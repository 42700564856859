import React from 'react';
import { Link, navigate } from 'gatsby';
import { GET_FOLLOWED_VEHICLES } from 'services/graphql/queries/vehicles-catalogue';
import PropTypes from 'prop-types';
import { useCheckNotAuthenticatedUsers } from 'shared/helpers/Auth';
import { useAuthenticatedQuery } from 'services/graphql/hooks';
import HeartIcon from 'svg/HeartIcon';
import Carousel from 'components/Carousel';
import styles from './vehicleCard.module.scss';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import Alert from 'components/Alert';
import ArrowWithCircle from 'svg/ArrowWithCircle';
import qs from 'qs';
import greyGooding from 'static/images/grey-gooding.png';
import {
  useFollowedVehiclesUpdate,
  useUnfollowVehiclesUpdate,
} from 'services/graphql/hooks/FollowedVehicles';
import { VEHICLE_PATH } from 'utils/pathsConstants';
import FeatureToggleManager from 'shared/helpers/FeatureToggle/featureToggleManager';
import { AUCTION_MOBILITY_WIDGET } from 'shared/helpers/FeatureToggle/constants';
import { useAuth0, User } from '@auth0/auth0-react';
import './amWidget.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import {
  amplitudeEvents,
  registerToBidEvent,
  trackEvent,
  trackLots,
} from 'utils/amplitude';

const VehicleCard = ({
  items = [],
  lotNumber,
  auction,
  makeModel,
  price,
  tag,
  cardType,
  filtersQuery,
  slug,
  arrowCircle,
  showDotsOnHover,
  fluidImages,
  salesForceId,
  lotAuctionMobilityId,
  activeAuction,
  auctionAuctionMobilityId,
  className,
  onlineBiddingAvailable,
  auctionSlug,
  recentlyViewed = false,
  list = false,
  type,
}) => {
  const { isAuthenticated, isLoading, user } = useAuth0();
  const searchQuery =
    filtersQuery && Object.keys(filtersQuery).length !== 0
      ? `?${qs.stringify(filtersQuery)}`
      : '';

  const isNotAuthenticated = useCheckNotAuthenticatedUsers();

  // Get followed vehicles list
  const { data: followedVehiclesData } = useAuthenticatedQuery(
    GET_FOLLOWED_VEHICLES,
    {
      skip: isNotAuthenticated,
    }
  );

  const followedVehicles =
    followedVehiclesData?.followedVehicles?.vehicles
      ?.map(item => item.salesForceId)
      .filter(item => item) ?? [];

  // Follow vehicle
  const [
    followVehicleRequest,
    { loading: followLoading, error: followError },
  ] = useFollowedVehiclesUpdate(salesForceId);

  // Unfollow vehicle
  const [
    unfollowVehicleRequest,
    { loading: unfollowLoading, error: unfollowError },
  ] = useUnfollowVehiclesUpdate(salesForceId);

  const followVehicle = e => {
    e.preventDefault();
    if (isNotAuthenticated) {
      if (isNotAuthenticated === 'unverified') navigate('/auth/unverified');
      else {
        localStorage.setItem(
          'currentRoute',
          `${window.location.pathname}${window.location.search}`
        );
        navigate('/auth/login');
      }
    } else {
      const variables = {
        variables: { vehicle: { vehicleId: salesForceId ?? null } },
      };
      !followedVehicles.includes(salesForceId)
        ? followVehicleRequest(variables)
        : unfollowVehicleRequest(variables);
    }
  };

  const featureToggleManager = new FeatureToggleManager();
  return (
    <>
      {(followLoading || unfollowLoading) && <Spinner />}
      {followError && <Alert color="danger" msg={followError.message} />}
      {unfollowError && <Alert color="danger" msg={unfollowError.message} />}
      <div
        className={`${
          cardType === 'grid'
            ? styles.appVehicleCard
            : styles.appVehicleListCard
        } ${className} ${
          recentlyViewed && list
            ? styles.appVehicleListView
            : recentlyViewed && styles.appVehicleCardRecentlyViewed
        }`}
      >
        <Link
          onClick={() => trackLots(makeModel, user?.email, auction)}
          to={`${VEHICLE_PATH(slug)}/${searchQuery}`}
        >
          <div className={styles.cardImage}>
            <Carousel
              items={!!items.length ? items : [{ src: greyGooding, title: '' }]}
              fluidImages={
                fluidImages && fluidImages.length > 0 ? fluidImages : null
              }
              controls={false}
              autoPLay={false}
              showDotsOnHover={showDotsOnHover}
              title={makeModel}
              className="small-indicators"
            />
          </div>
          <div className={styles.cardDetail}>
            <div className={styles.modelText}>
              {lotNumber ? (
                <span className={styles.lotText}>LOT {lotNumber}</span>
              ) : (
                ''
              )}

              <div className={styles.favorite}>
                <div
                  className={`d-flex align-items-start flex-column ${styles.favoriteContainer}`}
                >
                  <div className={`d-flex flex-row ${styles.firstRow}`}>
                    {tag ? (
                      <span className={styles.upcoming}>{tag}</span>
                    ) : (
                      <div>
                        <span className={styles.currentYear}>
                          {`${auction?.split(' | ')[0]}`} &nbsp;| &nbsp;
                        </span>
                        <span className={styles.auctionLink}>
                          {auction?.split(' | ')[1]?.trimStart()}
                        </span>
                      </div>
                    )}
                    {salesForceId && (
                      <Button
                        type={`${styles.bookmark} ${
                          arrowCircle && onlineBiddingAvailable
                            ? styles.bookmarkArrow
                            : ''
                        }`}
                        handleClick={e => {
                          followVehicle(e);
                          if (followedVehicles.includes(salesForceId)) {
                            trackEvent(amplitudeEvents.FAVORITES, {
                              user_email: user?.email,
                              lot_name: makeModel,
                            });
                          }
                        }}
                        ariaLabel="Follow"
                      >
                        <HeartIcon
                          solid={
                            followedVehicles.includes(salesForceId)
                              ? true
                              : false
                          }
                        />
                      </Button>
                    )}
                  </div>
                  {tag && auction?.split(' | ')[1] && type !== 'privateSales' && (
                    <div>
                      <span className={styles.currentYear}>
                        {`${auction?.split(' | ')[0]}`}
                        &nbsp;| &nbsp;
                      </span>

                      <span className={styles.auctionLink}>
                        {`${auction?.split(' | ')[1]?.trimStart()}`}
                      </span>
                    </div>
                  )}
                  {cardType === 'list' && (
                    <span className={`${styles.model} letterSpace d-block`}>
                      {makeModel}
                    </span>
                  )}
                </div>
                {arrowCircle && onlineBiddingAvailable && (
                  <ArrowWithCircle className={styles.arrowIcon} />
                )}
              </div>
            </div>
            <div>
              {cardType === 'grid' && (
                <div className={`${styles.model} letterSpace`}>{makeModel}</div>
              )}
              <span className={styles.price}>{price}</span>
            </div>
          </div>
        </Link>

        {featureToggleManager.isEnabled(AUCTION_MOBILITY_WIDGET) &&
          lotAuctionMobilityId &&
          auctionAuctionMobilityId && (
            <div
              id={`am-target-${lotAuctionMobilityId}`}
              lot-id={lotAuctionMobilityId}
              className="d-flex justify-content-between amCustomStyles align-items-end"
            >
              <div>
                <am-list-lot-status-label></am-list-lot-status-label>
                <am-list-bid-status-label></am-list-bid-status-label>
              </div>
              <div>
                <am-reserve-label></am-reserve-label>
                <am-timer></am-timer>
                {isLoading ? null : isAuthenticated ? (
                  <am-list-bid-box></am-list-bid-box>
                ) : (
                  activeAuction == 'true' && (
                    <div className="d-flex justify-content-end">
                      <Button
                        type="app-primary-button AM-login-button"
                        path={'/login'}
                        linkAsButton={true}
                        handleClick={() =>
                          registerToBidEvent(isAuthenticated, user.email)
                        }
                      >
                        Register to Bid
                      </Button>
                    </div>
                  )
                )}
              </div>
            </div>
          )}
      </div>
    </>
  );
};

VehicleCard.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  lotNumber: PropTypes.number,
  auction: PropTypes.string,
  makeModel: PropTypes.string,
  price: PropTypes.string,
  bookmarkClick: PropTypes.func,
  tag: PropTypes.string,
  cardType: PropTypes.string,
  slug: PropTypes.string,
  filtersQuery: PropTypes.object,
  arrowCircle: PropTypes.bool,
  following: PropTypes.bool,
  showDotsOnHover: PropTypes.bool,
};

VehicleCard.defaultProps = {
  lotNumber: 0,
  auction: '',
  makeModel: '',
  price: '',
  tag: '',
  cardType: 'grid',
  arrowCircle: true,
  following: false,
  showDotsOnHover: false,
  className: '',
};

export default VehicleCard;
